import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Location } from '@reach/router';

const ArticleTeaser = ({ article }) => (
  <Location>
    {locationProps => {
      const currentPage = locationProps.location.search.replace('?page=', '');

      return (
        <Link
          to={`/article?page=${article.link}`}
          state={{ fromFeed: true }}
          className={`article-teaser ${
            currentPage === article.link ? 'active' : ''
          }`}
        >
          {article.fly && (
            <small className="article-teaser__section">{article.fly}</small>
          )}
          <h4 className="article-teaser__title">{article.title}</h4>
          {article.description && (
            <p className="article-teaser__description">{article.description}</p>
          )}
        </Link>
      );
    }}
  </Location>
);

export default ArticleTeaser;

ArticleTeaser.propTypes = {
  article: PropTypes.shape({
    fly: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};
