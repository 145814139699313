import React, { useContext } from 'react';

import PrintEdition from './printEdition';
import ArticleTeaser from './articleTeaser';
import { store, Consumer } from '../context/store';
import actions from '../context/actions';

const ArticleList = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  
  return (
    <Consumer>
      {value => {
        const { printEdition, todaysNewsList } = value.state;

        return (
          <div className="economist__article-list-wrapper">
            <div className="economist__print-edition-toggle">
              <button
                className={printEdition ? '' : 'active'}
                onClick={() => dispatch({ type: actions.SHOW_TODAY })}
              >
                Today
              </button>
              <button
                className={printEdition ? 'active' : ''}
                onClick={() => dispatch({ type: actions.SHOW_PRINT_EDITION })}
              >
                Print Edition
              </button>
            </div>
            {printEdition ? (
              <PrintEdition />
            ) : (
              todaysNewsList.map((newsListItem) => (
                <ArticleTeaser
                  article={newsListItem}
                  key={`article-tease-${newsListItem.number}`}
                />
              ))
            )}
          </div>
        );
      }}
    </Consumer>
  );
};

export default ArticleList;
