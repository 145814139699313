import React, { useContext } from 'react';

import { store } from '../context/store';

const Wrapper = ({ children }) => {
  const globalState = useContext(store);

  let wrapperClasses = 'economist';
  wrapperClasses += globalState.state.reverse ? ' reverse' : '';
  wrapperClasses += globalState.state.darkTheme ? ' dark' : '';
  wrapperClasses += globalState.state.jsonMode ? ' json-mode' : '';
  wrapperClasses += globalState.state.jsonBodyMode ? ' json-body-mode' : '';
  wrapperClasses += globalState.state.noImages ? ' no-images' : '';

  return (
    <div className={wrapperClasses}>
      {children}
    </div>
  )
}

export default Wrapper;