import React from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';

import { Consumer } from '../context/store';

const PrintEdition = () => (
  <Location>
    {locationProps => {
      const currentPage = locationProps.location.search.replace('?page=', '');

      return (
        <Consumer>
          {context => {
            const {
              coverImage,
              edition,
              date,
              sections,
            } = context.state.printNewsList;

            return (
              <div className="economist__print-edition">
                <div className="economist__print-edition--image">
                  <img src={coverImage} alt={edition} />
                </div>
                <p className="economist__print-edition--date">{date}</p>
                {sections && sections.map(section => (
                  <div
                    key={section.sectionTitle}
                    className="economist__print-edition-section"
                  >
                    <p className="economist__print-edition-section--title">
                      {section.sectionTitle}
                    </p>
                    <div className="economist__print-edition-section--articles">
                      {section.links.map(article => (
                        <Link
                          key={article.link}
                          to={`/article?page=${article.link}`}
                          className={`print-edition-article-teaser ${
                            currentPage === article.link ? 'active' : ''
                          }`}
                        >
                          {article.flyTitle && (
                            <span className="print-edition-article-teaser__fly-title">
                              {article.flyTitle}
                            </span>
                          )}
                          {article.title && (
                            <span className="print-edition-article-teaser__title">
                              {article.title}
                            </span>
                          )}
                          {article.titleSub && (
                            <span className="print-edition-article-teaser__sub-title">
                              {article.titleSub}
                            </span>
                          )}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
        </Consumer>
      );
    }}
  </Location>
);

export default PrintEdition;