import React from 'react';

import spinner from '../images/spinner.png';

const Loading = () => (
  <div className="economist__password-wrapper">
    <h1>The Economist</h1>
    <img
      src={spinner}
      alt="Loading Spinner"
      className="economist__spinner"
    />
  </div>
);

export default Loading;