import React, { useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import ArticleList from './articleList';
import Options from './options';
import Wrapper from './wrapper';
import { store, Consumer } from '../context/store';
import actions from '../context/actions';
import Loading from './loading';

import '../scss/main.scss';

const Layout = ({ children }) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { password } = state;

  const dateString = new Date().toDateString().replace(/ /g, '');
  const baseUrl = '/.netlify/functions/economist';

  useEffect(initCachedOptions, []);
  useEffect(checkForCachedNews, [password]);

  function initCachedOptions() {
    dispatch({
      type: actions.SET_LOCAL_STORAGE,
      payload: localStorage,
    });

    const cachedOptions = localStorage.getItem('appOptions');

    if (cachedOptions) {
      dispatch({
        type: actions.SET_OPTIONS,
        payload: JSON.parse(cachedOptions),
      });
    }

    const cachedPassword = localStorage.getItem('economistApiPassword');

    if (cachedPassword) {
      dispatch({
        type: actions.SET_PASSWORD,
        payload: cachedPassword,
      });
    } else {
      navigate('/login');
    }
  }

  function checkForCachedNews() {
    if (password.length > 0) {
      const cachedNewsList = localStorage.getItem(dateString);
      const cachedPrintNewsList = localStorage.getItem(`${dateString}Print`);

      if (cachedNewsList) {
        dispatch({
          type: actions.SET_TODAYS_NEWS_LIST,
          payload: JSON.parse(cachedNewsList),
        });
      } else {
        fetchArticles('/');
      }

      if (cachedPrintNewsList) {
        dispatch({
          type: actions.SET_PRINT_NEWS_LIST,
          payload: JSON.parse(cachedPrintNewsList),
        });
      } else {
        fetchArticles('/printedition');
      }
    }
  }

  async function fetchArticles(path) {
    const { password } = state;

    try {
      const res = await axios.get(`${baseUrl}?key=${password}&page=${path}`);

      if (path === '/') {
        localStorage.setItem(dateString, JSON.stringify(res.data));

        dispatch({
          type: actions.SET_TODAYS_NEWS_LIST,
          payload: res.data,
        });
      } else if (path === '/printedition') {
        localStorage.setItem(`${dateString}Print`, JSON.stringify(res.data));

        dispatch({
          type: actions.SET_PRINT_NEWS_LIST,
          payload: res.data,
        });
      }
    } catch (err) {
      console.log('Error: ', err);
    }
  }

  return (
    <Consumer>
      {context => {
        const { todaysNewsList } = context.state;

        if (!todaysNewsList.length) {
          return <Loading />;
        }

        return (
          <Wrapper>
            <ArticleList />
            <main className="economist__article-wrapper">{children}</main>
            <Options />
          </Wrapper>
        );
      }}
    </Consumer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
